<template>
  <div class="pg-verses-list">
    <div :class="isVerseItemSelected == false ? 'd-none' : ''" class="mb-3">
      <div class="row">
        <div class="col-lg-2 col-12">
          <button
            class="btn btn-secondary w-100 pg-verses-list__button-action"
            @click="CopyVersesSelectedToClipboard"
          >
            <font-awesome-icon :icon="['fas', 'clipboard']" size="1x" />
            &nbsp;copiar
          </button>
        </div>

        <div class="col-lg-3 col-12">
          <button
            class="btn btn-primary w-100 pg-verses-list__button-action"
            @click="
              () => {
                setShowModalCompare(true);
              }
            "
          >
            <font-awesome-icon :icon="['fas', 'code-compare']" size="1x" />
            &nbsp;comparar verso
          </button>
        </div>

        <div class="col-lg-2 col-12">
          <button
            class="btn btn-danger w-100 pg-verses-list__button-action"
            @click="CancelSelection"
          >
            <font-awesome-icon :icon="['fas', 'x']" size="1x" />
            &nbsp;cancelar
          </button>
        </div>
      </div>
    </div>

    <VerseModalCompare
      v-if="isShowModalCompare"
      :book="book"
      :chapter="chapter"
      :verses="verseItemSelected"
      :show="isShowModalCompare"
      :on-close="
        () => {
          setShowModalCompare(false);
        }
      "
    />

    <div
      v-if="showFlash"
      class="alert d-inline-block p-2"
      :class="flash.class"
      role="alert"
    >
      <font-awesome-icon :icon="['fas', 'check']" /> {{ flash.text }}
    </div>

    <ul class="list-unstyled list-group">
      <li
        v-for="(verse, index) in verses"
        :key="`verse-li-${verse.verse}`"
        class="list-group-item p-0"
      >
        <VerseItem
          ref="verseItemRef"
          :key="`verse-item-${verse.verse}`"
          :verse="verse"
          :book="book"
          :root-class="getVerseItemClass(verse.verse)"
          @click="() => verseItemClick(verse, index)"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { BookApi, VerseApi } from "@/types/app";
import VerseItem from "@/components/VerseItem.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Tooltip } from "bootstrap";
import { CopyToClipboard } from "@/helper/clipboard-helper";
import VerseModalCompare from "./VerseModalCompare.vue";

defineComponent({
  name: "VersesList",
  components: { VerseItem, VerseModalCompare, FontAwesomeIcon },
});

const props = defineProps({
  verses: {
    type: Array as PropType<VerseApi[]>,
    required: true,
    validator(value: unknown): boolean {
      return true;
    },
    default: () => Array as PropType<VerseApi[]>,
  },
  book: {
    type: Object as PropType<BookApi>,
    required: true,
  },
  chapter: {
    type: Number,
    required: true,
  },
});

const verseItemRef = ref<HTMLElement[]>([]);
const verseItemSelected = ref([] as VerseApi[]);
const isVerseItemSelected = computed(() => {
  return verseItemSelected.value.length > 0;
});

const flash = reactive({
  show: false,
  text: "Copied",
  class: "alert-success",
});
const showFlash = computed(() => flash.show);
const showModalCompare = ref(false);
const isShowModalCompare = computed(() => {
  return showModalCompare.value;
});

function setShowModalCompare(show: boolean) {
  showModalCompare.value = show;
}

function sortVersesSelected(a: VerseApi, b: VerseApi) {
  if (a.verse === b.verse) {
    return 0;
  } else {
    return a.verse < b.verse ? -1 : 1;
  }
}

function verseItemClick(verse: VerseApi, index: number) {
  const indexExists = verseItemSelected.value.findIndex((v: VerseApi) => {
    return v.verse === verse.verse;
  });

  const exists = indexExists !== -1;

  if (!exists) {
    verseItemSelected.value.push(verse);
  } else {
    verseItemSelected.value = verseItemSelected.value.filter((v: VerseApi) => {
      return v.verse !== verse.verse;
    });
  }

  verseItemSelected.value.sort(sortVersesSelected);
}

function getVerseItemClass(verse: number) {
  const indexExists = verseItemSelected.value.findIndex((v: VerseApi) => {
    return v.verse === verse;
  });
  return indexExists !== -1 ? "selected" : "";
}

function getVersesSelectedToClipboard() {
  const verses = verseItemSelected.value;

  let text = props.book.name + " " + props.chapter + "\n\n";

  verses.forEach((verse: VerseApi) => {
    text += verse.verse + ". " + verse.verse_text + "\n";
  });

  return text.substring(0, text.length - 1);
}

function CopyVersesSelectedToClipboard() {
  CopyToClipboard(getVersesSelectedToClipboard());
  verseItemSelected.value = [];
  flash.show = true;

  setTimeout(() => {
    flash.show = false;
  }, 2000);
}

function CancelSelection() {
  verseItemSelected.value = [];
}
</script>
