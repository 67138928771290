<script lang="ts" setup>
import { PropType, computed, defineComponent } from "vue";
import { BookApi } from "@/types/app";
import { GetLinkHelper } from "@/helper/link-helper";
import { useStore } from "vuex";

defineComponent({
  name: "BookList",
});

defineProps({
  books: {
    type: Object as PropType<{ old: BookApi[]; new: BookApi[] }>,
    required: false,
    default: () => {
      return { old: [], new: [] };
    },
  },
});

const store = useStore();

const linkHelper = GetLinkHelper();

const version = computed(() => store.state.version);

function getLink(abbrev: string) {
  return linkHelper.getBookChaptersPage(version.value, abbrev);
}
</script>

<template>
  <div class="book-list">
    <div>
      <h5 class="book-list__testament">Velho Testamento</h5>

      <ul class="list-unstyled row book-list__list">
        <li v-for="book in books.old" :key="book.abbrev" class="col-lg-2 col-4">
          <router-link :to="getLink(book.abbrev)">{{ book.name }}</router-link>
        </li>
      </ul>
    </div>

    <div class="mt-5">
      <h5 class="book-list__testament">Novo Testamento</h5>

      <ul class="list-unstyled row book-list__list">
        <li v-for="book in books.new" :key="book.abbrev" class="col-lg-2 col-4">
          <router-link :to="getLink(book.abbrev)">{{ book.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
