import { createStore } from "vuex";
import { BookApi, VersionApi } from "@/types/app";
import { RequestHelper } from "@/helper/request-helper";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    books: [] as BookApi[],
    versions: [] as VersionApi[],
    version: "acf" as string,
    isScreenLoading: false as boolean,
  },
  getters: {
    getCurrentVersion: (state) => () => {
      return state.versions.find((v) => v.code === state.version);
    },
    getVersionByCode: (state) => (code: string) => {
      return state.versions.find((v) => v.code === code);
    },
    getBookByAbbrev: (state) => (abbrev: string) => {
      return state.books.find((b) => b.abbrev === abbrev);
    },
    getBooksByTestament: (state) => () => {
      return {
        old: state.books.filter((b) => b.testament === "old"),
        new: state.books.filter((b) => b.testament === "new"),
      };
    },
  },
  mutations: {
    setBooks(state, payload) {
      state.books = payload;
    },
    setVersions(state, payload) {
      state.versions = payload;
    },
    setVersion(state, payload) {
      state.version = payload;
    },
    setScreenLoading(state, payload) {
      state.isScreenLoading = payload;
    },
  },
  actions: {
    setScreenLoading(context, isLoading) {
      context.commit("setScreenLoading", isLoading);
    },
    async fetchData(context) {
      if (context.state.books.length > 0) {
        return;
      }

      try {
        const response = await RequestHelper.getJson("/data");

        if (response.status === "success") {
          context.commit("setBooks", response.books);
          context.commit("setVersions", response.versions);
          context.commit("setVersion", response.versionDefault);
        }
      } catch (error: any) {
        console.error(error);
      }
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      paths: ["books"],
      key: "books",
      storage: window.sessionStorage,
    }),
    createPersistedState({
      paths: ["versions"],
      key: "versions",
      storage: window.sessionStorage,
    }),
    createPersistedState({
      paths: ["version"],
      key: "version",
      storage: window.sessionStorage,
    }),
  ],
});
