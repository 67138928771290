<template>
  <section class="version-books cid-srwQZCq9Ba">
    <div class="container">
      <BreadCrumb :breadcrumb="breadcrumb" />

      <h1 class="version-books--pg-title">
        {{ version.name }} ({{ version.code.toUpperCase() }})
      </h1>

      <BookList :books="books" />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { BreadCrumbType, VersionApi } from "@/types/app";
import { useRoute, useRouter } from "vue-router";
import { useMeta } from "vue-meta";
import UrlHelper from "@/helper/url-helper";
import BreadCrumb from "@/components/BreadCrumb.vue";
import BookList from "@/components/BookList.vue";

defineComponent({
  name: "VersionBooks",
  components: { BreadCrumb, BookList },
});

const store = useStore();

const route = useRoute();
const router = useRouter();

const version = ref({ name: "", code: "" } as VersionApi);
const breadcrumb = ref([] as BreadCrumbType[]);

const books = computed(() => store.getters.getBooksByTestament());

function fetchData() {
  const versionStore = store.getters.getVersionByCode(route.params["version"]);

  if (versionStore === undefined) {
    UrlHelper.RouterBack(router);
    return;
  }

  version.value = versionStore;

  loadBreadcrumb();
}

function loadMeta() {
  useMeta({
    title: `${process.env.VUE_APP_APP_NAME} ${
      version.value.name
    } - ${version.value.code.toUpperCase()}`,
    description: `Medite nas Escrituras de Deus na versão ${
      version.value.name
    } - ${version.value.code.toUpperCase()}`,
    keywords: `biblia, biblia online, Palavra de Deus, ${version.value.name}`,
    htmlAttrs: { lang: "en", amp: true },
  });
}

function loadBreadcrumb() {
  breadcrumb.value = [
    {
      name: "Home",
      link: "/",
      disabled: false,
    },
    {
      name: "Livros",
      link: "/",
      disabled: true,
    },
  ] as BreadCrumbType[];
}

onMounted(() => {
  fetchData();
  loadMeta();
});
</script>
