import { BookApi } from "@/types/app";
import { Store } from "vuex";

export declare type NavigationBookType = {
  book: BookApi;
  chapter: number;
};

export class NavigationService {
  constructor(private readonly store: Store<any>) {}

  public getNavigation(
    book: string,
    chapter: number
  ): {
    before: NavigationBookType | undefined;
    after: NavigationBookType | undefined;
  } {
    return {
      before: this.getBefore(book, chapter),
      after: this.getAfter(book, chapter),
    };
  }

  public getBefore(
    bookCode: string,
    chapter: number
  ): NavigationBookType | undefined {
    const currentBook = this.store.getters.getBookByAbbrev(bookCode);

    if (currentBook === undefined) {
      return undefined;
    }

    // Previous Book
    if (chapter === 1) {
      // There is no previous book
      if (currentBook.order === 1) {
        return undefined;
      }

      // Get Previous Book By Order
      const book = this.getBookByOrder(currentBook.order - 1);

      if (book === undefined) {
        return undefined;
      }

      return {
        book,
        chapter: book.chapters,
      } as NavigationBookType;
    }

    return {
      book: currentBook,
      chapter: chapter - 1,
    };
  }

  public getAfter(
    bookCode: string,
    chapter: number
  ): NavigationBookType | undefined {
    const currentBook = this.store.getters.getBookByAbbrev(bookCode);

    if (currentBook === undefined) {
      return undefined;
    }

    // Last Chapter of Book
    if (currentBook.chapters == chapter) {
      const nextBook = this.getBookByOrder(currentBook.order + 1);

      if (nextBook === undefined) {
        return undefined;
      }

      return {
        book: nextBook,
        chapter: 1,
      };
    }

    return {
      book: currentBook,
      chapter: chapter + 1,
    };
  }

  public getBookByOrder(bookOrder: number) {
    return this.store.state.books.find((b: BookApi) => b.order === bookOrder);
  }
}
