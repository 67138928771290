import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import BookChapters from "@/views/BookChapters.vue";
import BookChapterVerses from "@/views/BookChapterVerses.vue";
import VersionBooks from "@/views/VersionBooks.vue";
import DefaultLayout from "@/views/layout/DefaultLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: { layout: DefaultLayout },
    component: HomeView,
  },
  {
    path: "/version/:version",
    name: "version-books",
    meta: { layout: DefaultLayout },
    component: VersionBooks,
  },
  {
    path: "/version/:version/:book",
    name: "book-chapters",
    meta: { layout: DefaultLayout },
    component: BookChapters,
  },
  {
    path: "/version/:version/:book/:chapter",
    name: "book-chapter-verses",
    meta: { layout: DefaultLayout },
    component: BookChapterVerses,
  },
  {
    path: "/about",
    name: "about",
    meta: { layout: DefaultLayout },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { layout: DefaultLayout },
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/ContactView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    return {
      top: 0,
      left: 0,
    };
  },
});

export default router;
