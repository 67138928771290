<template>
  <section class="pg-book-chapter-verses cid-srwQZCq9Ba">
    <div class="container">
      <BreadCrumb :breadcrumb="breadcrumb" />

      <h1 class="title mb-4">{{ book.name }} - Capitulo {{ chapter }}</h1>

      <VersesList :verses="verses" :book="book" :chapter="chapter" />

      <NavigationBook
        v-if="navigationBook"
        :book="bookNavigation"
        :chapter="chapterNavigation"
        :callback="callbackNav"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, ref } from "vue";
import { RequestHelper } from "@/helper/request-helper";
import { BookApi, VersionApi, VerseApi, BreadCrumbType } from "@/types/app";
import { useStore } from "vuex";
import { GetLinkHelper } from "@/helper/link-helper";
import { useRoute, useRouter } from "vue-router";
import UrlHelper from "@/helper/url-helper";
import VersesList from "@/components/VersesList.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import NavigationBook from "@/components/NavigationBook.vue";
import { NavigationBookType } from "@/services/navigation-service";

defineComponent({
  name: "BookChapterVerses",
  components: {
    BreadCrumb,
    VersesList,
    NavigationBook,
  },
});

const store = useStore();
const route = useRoute();
const router = useRouter();

const version = ref({} as VersionApi | undefined);
const book = ref({} as BookApi);
const chapter = ref(0 as number);
const verses = ref([] as VerseApi[]);
const breadcrumb = ref([] as BreadCrumbType[]);
const navigationBook = ref(false);
const linkHelper = GetLinkHelper();

const bookNavigation = computed(() => {
  return book.value.abbrev !== undefined ? book.value.abbrev : "";
});
const chapterNavigation = computed(() => {
  return chapter.value;
});

async function fetchData(): Promise<boolean> {
  try {
    const result = await RequestHelper.postJson("/book/chapter/verses", {
      version: version.value?.code,
      book: book.value?.abbrev,
      chapter: chapter.value.toString(),
    });

    if (result.status !== "success") {
      UrlHelper.RouterBack(router);
    }

    verses.value = result.verses;

    return new Promise((resolve, reject) => {
      resolve(true);
    });
  } catch (error: any) {
    return new Promise((resolve, reject) => {
      reject(true);
    });
  }
}

function loadBreadcrumb() {
  breadcrumb.value = [
    {
      name: "Home",
      link: "/",
      disabled: false,
    },
    {
      name: "Livros",
      link: linkHelper.getVersionBooksPage(store.state.version),
      disabled: false,
    },
    {
      name: "Capítulos",
      link: linkHelper.getBookChaptersPage(
        store.state.version,
        `${book.value?.abbrev}`
      ),
      disabled: false,
    },
    {
      name: "Versículos",
      link: "",
      disabled: true,
    },
  ] as BreadCrumbType[];
}

function callbackNav(nav: NavigationBookType) {
  const page = linkHelper.getBookChapterVersesPage(
    version.value !== undefined ? version.value.code : "",
    nav.book.abbrev,
    nav.chapter.toString()
  );
  router.push({ path: page, force: true });
}

onMounted(async () => {
  await store.dispatch("setScreenLoading", true);

  version.value = store.getters.getVersionByCode(route.params["version"]);
  book.value = store.getters.getBookByAbbrev(route.params["book"]);
  chapter.value = parseInt(route.params["chapter"].toString(), 10);

  if (chapter.value < 1 || chapter.value > book.value.chapters) {
    UrlHelper.RouterBack(router);

    return;
  }

  loadBreadcrumb();

  await fetchData();

  navigationBook.value = true;

  await store.dispatch("setScreenLoading", false);

  if (route.hash) {
    const el = document.getElementById(route.hash.replace("#", ""));
    if (el !== null) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
});
</script>
