<script lang="ts" setup>
import { PropType, defineComponent } from "vue";
import { BreadCrumbType } from "@/types/app";

defineComponent({
  name: "BreadCrumb",
});

defineProps({
  breadcrumb: {
    type: Array as PropType<BreadCrumbType[]>,
    required: false,
    validator(value: unknown): boolean {
      return true;
    },
    default: () => [] as PropType<BreadCrumbType[]>,
  },
});
</script>

<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          v-for="(item, index) in breadcrumb"
          :key="index"
          class="breadcrumb-item"
          :class="item.disabled ? 'active' : ''"
        >
          <router-link v-if="!item.disabled" :to="item.link">{{
            item.name
          }}</router-link>
          <span v-else>{{ item.name }}</span>
        </li>
      </ol>
    </nav>
  </div>
</template>
