<template>
  <div class="nav-book row mt-3 mb-3">
    <div class="col-6">
      <div
        ref="tooltipBeforeRef"
        :title="beforeTitleTooltip"
        class="d-inline-block float-start"
      >
        <FontAwesomeIcon
          v-if="nav.before !== undefined"
          :icon="['fas', 'arrow-left']"
          size="lg"
          class="float-start"
          style="cursor: pointer"
          @click="callback(nav.before)"
        />
      </div>
    </div>
    <div class="col-6">
      <div
        ref="tooltipAfterRef"
        :title="afterTitleTooltip"
        class="d-inline-block float-end"
      >
        <FontAwesomeIcon
          v-if="nav.after !== undefined"
          :icon="['fas', 'arrow-right']"
          size="lg"
          class="float-end"
          style="cursor: pointer"
          @click="callback(nav.after)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStore } from "vuex";
import {
  NavigationService,
  NavigationBookType,
} from "@/services/navigation-service";
import { Tooltip } from "bootstrap";

defineComponent({
  name: "NavigationBook",
  components: { FontAwesomeIcon },
});

const props = defineProps({
  book: {
    type: String,
    required: true,
    validator(value: unknown): boolean {
      return typeof value === "string";
    },
  },
  chapter: {
    type: Number,
    required: true,
  },
  callback: {
    type: Function,
    required: true,
  },
});

const store = useStore();

const navigationService = new NavigationService(store);

const nav = reactive({
  before: undefined as NavigationBookType | undefined,
  after: undefined as NavigationBookType | undefined,
});

const tooltipBeforeRef = ref();
const tooltipAfterRef = ref();
const tooltipBefore = ref();
const tooltipAfter = ref();

const beforeTitleTooltip = computed(() => {
  if (nav.before === undefined || nav.before.book === undefined) {
    return "";
  }

  return nav.before.book.name + " Capítulo " + nav.before.chapter;
});

const afterTitleTooltip = computed(() => {
  if (nav.after === undefined || nav.after.book === undefined) {
    return "";
  }

  return nav.after.book.name + " Capítulo " + nav.after.chapter;
});

onMounted(() => {
  tooltipBefore.value = new Tooltip(tooltipBeforeRef.value);
  tooltipAfter.value = new Tooltip(tooltipAfterRef.value);

  nav.before = navigationService.getBefore(props.book, props.chapter);
  nav.after = navigationService.getAfter(props.book, props.chapter);
});
</script>
