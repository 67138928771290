import { Router } from "vue-router";

class UrlHelper {
  public static RouterBack(router: Router) {
    if (window.history.state.back === null) {
      (async function () {
        await router.push("/");
      })();
    } else {
      router.back();
    }
  }
}

export default UrlHelper;
