<template>
  <div class="pg-home">
    <section class="cid-srwQZCq9Ba">
      <div class="container">
        <div>
          <h3 class="pg-home__versions-title">Versões da Bíblia</h3>
        </div>

        <VersionList :versions="versions" />

        <div class="mt-5"></div>

        <BookList :books="books" />
      </div>
    </section>

    <!-- <section class="features11 cid-srwQZCq9Ba d-none">
      <div class="container">
        <div class="section_title mbr-pb-5">
          <div class="mbr-row mbr-jc-c">
            <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6">
              <h4
                class="mbr-section-subtitle mbr-fonts-style mbr-semibold display-4"
              >
                BENEFITS
              </h4>
              <h3
                class="mbr-fonts-style mbr-black mbr-section-title mbr-semibold mbr-pb-2 display-5"
              >
                Your Comfort is Our Priority
              </h3>
            </div>
            <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6">
              <p class="mbr-fonts-style mbr-text display-7">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aperiam, odit aspernatur corporis et dolore itaque. Deserunt
                eaque labore provident, dicta fuga rerum nesciunt error.
              </p>
            </div>
          </div>
        </div>

        <div class="mbr-row">
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-letter mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M0 4v16h24V4H0zm19.8 2L12 10.7 4.2 6h15.6zM2 18V7l10 6 10-6v11H2z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Mail
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-desktop mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M24 18V2H0v16h9v2H7v2h10v-2h-2v-2h9zm-11 2h-2v-2h2v2zM2 16V4h20v12H2z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Visual
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-folder mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M11.4 6l-3-3H0v19h24V6H11.4zM22 20H2V5h5.6l3 3H22v12z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                File
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-calendar mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M19 3V2h-2v1H7V2H5v1H0v19h24V3h-5zm3 17H2V5h3v1h2V5h10v1h2V5h3v15zM5 12h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2zM5 8h2v2H5V8zm4 0h2v2H9V8zm4 0h2v2h-2V8zm4 0h2v2h-2V8zM5 16h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Calendar
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-briefcase mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M17 6V2H7v4H0v16h24V6h-7zM9 4h6v2H9V4zm13 16H2v-7h20v7zm0-9H2V8h20v3z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Suitcase
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-chat mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M0 23.4V2h24v15H6.4L0 23.4zM2 4v14.6L5.6 15H22V4H2z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Chat
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-globe mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zM4.9 5H9c.6 0 1 .4 1 1s-.4 1-1 1H5.5C4.1 7 3 8.1 3 9.5S4.1 12 5.5 12H6c.6 0 1 .4 1 1s-.4 1-1 1H2.2c-.1-.6-.2-1.3-.2-2 0-2.7 1.1-5.2 2.9-7zM2.8 16H6c1.7 0 3-1.3 3-3s-1.3-3-3-3h-.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H9c1.7 0 3-1.3 3-3s-1.3-3-3-3H7.7C9 2.4 10.4 2 12 2c4.1 0 7.6 2.5 9.2 6H17c-1.7 0-3 1.3-3 3s1.3 3 3 3h.5c.3 0 .5.2.5.5s-.2.5-.5.5H13c-1.7 0-3 1.3-3 3s1.3 3 3 3h3.3c-1.3.6-2.8 1-4.3 1-4.1 0-7.6-2.5-9.2-6zm16.3 3H13c-.6 0-1-.4-1-1s.4-1 1-1h4.5c1.4 0 2.5-1.1 2.5-2.5S18.9 12 17.5 12H17c-.6 0-1-.4-1-1s.4-1 1-1h4.8c.1.6.2 1.3.2 2 0 2.7-1.1 5.2-2.9 7z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Global
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div class="mbr-col-lg-3 mbr-col-md-6 mbr-col-sm-12">
            <div class="widgets_item">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-sites mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        d="M24 18V2H0v16h9v2H7v2h10v-2h-2v-2h9zm-11 2h-2v-2h2v2zM2 16V4h20v12H2zm10-9h8v2h-8V7zm0 4h4v2h-4v-2zm-8 2h6V7H4v6zm2-4h2v2H6V9z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title mbr-black mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Sites
              </h5>
              <p
                class="card_text mbr-fonts-style align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features6-i" class="features6 cid-srwSBXwMD5 d-none">
      <div class="container">
        <div class="mbr-row mbr-jc-c mbr-row-reverse">
          <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6">
            <div class="image-block md-pb">
              <amp-img
                src="assets/images/12.jpeg"
                layout="responsive"
                width="741.6"
                height="741.6"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer style="padding-top: 100%"></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

                <img
                  decoding="async"
                  alt=""
                  src="assets/images/12.jpeg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
            </div>
          </div>
          <div class="mbr-col-sm-12 mbr-col-md-10 mbr-col-lg-6">
            <div class="text-block md-pb">
              <h4
                class="mbr-section-subtitle mbr-fonts-style mbr-semibold display-4"
              >
                WHO WE ARE
              </h4>
              <h3
                class="mbr-section-title mbr-fonts-style mbr-black mbr-semibold display-2"
              >
                We’re Creative Coworking Working Space For Sucsess
              </h3>
              <p class="mbr-text mbr-pt-2 mbr-fonts-style display-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Explicabo quisquam optio eos harum ad corporis qui porro quia
                incidunt! Laudantium, quasi esse? Quisquam, fuga expedita iure
                labore, asperiores ratione dolorem!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features12-7" class="features12 cid-srwRa9Px7Z d-none">
      <div class="container">
        <div class="mbr-row mbr-jc-c">
          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
            <div class="card_wrap">
              <amp-img
                src="assets/images/01.jpg"
                layout="responsive"
                width="510"
                height="340"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.6667%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

                <img
                  decoding="async"
                  alt=""
                  src="assets/images/01.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
              <div class="card_content mbr-flex mbr-column mbr-jc-c">
                <h5
                  class="mbr-section-title title mbr-white mbr-fonts-style mbr-semibold display-5"
                >
                  How to success through market segmentation
                </h5>
              </div>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
            <div class="card_wrap info_card mbr-flex mbr-column mbr-jc-c">
              <h5
                class="mbr-section-title info_title mbr-white mbr-fonts-style mbr-semibold display-5"
              >
                3 clear warnings that say your goals are too small
              </h5>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
            <div class="card_wrap">
              <amp-img
                src="assets/images/06.jpg"
                layout="responsive"
                width="510"
                height="340"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.6667%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

                <img
                  decoding="async"
                  alt=""
                  src="assets/images/06.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
              <div class="card_content mbr-flex mbr-column mbr-jc-c">
                <h5
                  class="mbr-section-title mbr-white title mbr-fonts-style mbr-semibold display-5"
                >
                  10 things successful people do differently Career
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features13-8" class="features13 cid-srwRaqO7j5 d-none">
      <div class="container">
        <div class="mbr-row mbr-jc-c">
          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-8">
            <div class="card_wrap">
              <amp-img
                src="assets/images/03.jpg"
                layout="responsive"
                width="750"
                height="500"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.6667%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

                <img
                  decoding="async"
                  alt=""
                  src="assets/images/03.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
              <div class="card_content mbr-flex mbr-column mbr-jc-c">
                <h5
                  class="mbr-section-title title mbr-white mbr-fonts-style mbr-semibold display-5"
                >
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Soluta quia minus doloremque repellendus.
                </h5>
              </div>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
            <div class="card_wrap info_card mbr-flex mbr-column mbr-jc-c">
              <h4
                class="mbr-section-subtitle mbr-white mbr-fonts-style mbr-semibold display-4"
              >
                WORKSPACE
              </h4>
              <h5
                class="mbr-section-title info_title mbr-white mbr-fonts-style mbr-semibold display-5"
              >
                3 clear warnings that say your goals are too small
              </h5>

              <div class="mbr-section-btn mbr-pt-4">
                <a class="btn btn-md btn-primary display-4" href="#"
                  ><span
                    class="mobi-mbri mobi-mbri-arrow-next mbr-iconfont mbr-iconfont-btn mbr-bold"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="11"
                      height="11"
                    >
                      <path
                        d="M4.6 22.6L15.2 12 4.6 1.4 6 0l12 12L6 24z"
                      ></path></svg></span
                  >Read More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features14-9" class="features14 cid-srwRaESBu3 d-none">
      <div class="container">
        <div class="mbr-row mbr-jc-c">
          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
            <div class="card_wrap info_card mbr-flex mbr-column mbr-jc-c">
              <div class="card-img align-center">
                <div class="iconfont-wrapper">
                  <span class="mbr-iconfont mobi-mbri-hot-cup mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="56"
                      height="56"
                    >
                      <path
                        d="M20 10h-1V7H1v11c0 3.3 2.7 6 6 6h6c3 0 5.4-2.2 5.9-5H20c2.2 0 4-1.8 4-4v-1c0-2.2-1.8-4-4-4zm-3 8c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V9h14v9zm5-3c0 1.1-.9 2-2 2h-1v-5h1c1.1 0 2 .9 2 2v1zM9 5H7V0h2v5zm4 0h-2V3h2v2z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <h5
                class="mbr-section-title info_title mbr-white mbr-fonts-style align-center mbr-semibold mbr-pt-3 display-7"
              >
                Comfort
              </h5>
              <p
                class="mbr-text mbr-fonts-style mbr-white align-center mbr-pt-1 display-4"
              >
                Lorem ipsum dolor sit amet At ducimus aliquam necessitatibus?
              </p>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
            <div class="card_wrap">
              <amp-img
                src="assets/images/07.jpg"
                layout="responsive"
                width="509.204368174727"
                height="340"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.7708%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

                <img
                  decoding="async"
                  alt=""
                  src="assets/images/07.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
              <div class="card_content mbr-flex mbr-column mbr-jc-c">
                <h5
                  class="mbr-section-title title mbr-white mbr-fonts-style mbr-semibold display-5"
                >
                  How to success through market segmentation
                </h5>
              </div>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-8 mbr-col-lg-4">
            <div class="card_wrap">
              <amp-img
                src="assets/images/04.jpg"
                layout="responsive"
                width="510"
                height="340"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.6667%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>

                <img
                  decoding="async"
                  alt=""
                  src="assets/images/04.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
              <div class="card_content mbr-flex mbr-column mbr-jc-c">
                <h5
                  class="mbr-section-title mbr-white title mbr-fonts-style mbr-semibold display-5"
                >
                  10 things successful people do differently career
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="slider1-m" class="slider1 slider cid-srwU3IHSya d-none">
      <div class="container-fluid">
        <div class="mbr-pb-5 section_title">
          <h4
            class="mbr-fonts-style mbr-section-subtitle mbr-white mbr-semibold align-center mbr-pb-1 display-4"
          >
            EXAMPLES
          </h4>
          <h3
            class="mbr-fonts-style mbr-section-title mbr-white mbr-semibold align-center display-2"
          >
            We create a space for creativity
          </h3>
        </div>
        <div class="slider-box">
          <amp-carousel
            class="carousel i-amphtml-element i-amphtml-layout-fixed-height i-amphtml-layout-size-defined i-amphtml-carousel-has-controls i-amphtml-built i-amphtml-layout"
            layout="fixed-height"
            type="carousel"
            height="300"
            i-amphtml-layout="fixed-height"
            style="height: 300px"
            ><div class="i-amphtml-scrollable-carousel-container" tabindex="-1">
              <div
                class="carousel-img mbr-col-sm-12 amp-carousel-slide amp-scrollable-carousel-slide"
              >
                <amp-img
                  class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  src="assets/images/05.jpg"
                  alt=""
                  width="450"
                  height="300"
                  layout="responsive"
                  i-amphtml-layout="responsive"
                  ><i-amphtml-sizer
                    style="padding-top: 66.6667%"
                  ></i-amphtml-sizer>
                  <div placeholder="" class="placeholder amp-hidden">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                  <img
                    decoding="async"
                    alt=""
                    src="assets/images/05.jpg"
                    class="i-amphtml-fill-content i-amphtml-replaced-content"
                /></amp-img>
              </div>
              <div
                class="carousel-img mbr-col-sm-12 amp-carousel-slide amp-scrollable-carousel-slide"
              >
                <amp-img
                  class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  src="assets/images/09.jpg"
                  alt=""
                  width="450"
                  height="300"
                  layout="responsive"
                  i-amphtml-layout="responsive"
                  ><i-amphtml-sizer
                    style="padding-top: 66.6667%"
                  ></i-amphtml-sizer>
                  <div placeholder="" class="placeholder amp-hidden">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                  <img
                    decoding="async"
                    alt=""
                    src="assets/images/09.jpg"
                    class="i-amphtml-fill-content i-amphtml-replaced-content"
                /></amp-img>
              </div>
              <div
                class="carousel-img mbr-col-sm-12 amp-carousel-slide amp-scrollable-carousel-slide"
              >
                <amp-img
                  class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  src="assets/images/01.jpg"
                  alt=""
                  width="450"
                  height="300"
                  layout="responsive"
                  i-amphtml-layout="responsive"
                  ><i-amphtml-sizer
                    style="padding-top: 66.6667%"
                  ></i-amphtml-sizer>
                  <div placeholder="" class="placeholder amp-hidden">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                  <img
                    decoding="async"
                    alt=""
                    src="assets/images/01.jpg"
                    class="i-amphtml-fill-content i-amphtml-replaced-content"
                /></amp-img>
              </div>
              <div
                class="carousel-img mbr-col-sm-12 amp-carousel-slide amp-scrollable-carousel-slide"
              >
                <amp-img
                  class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  src="assets/images/13.jpg"
                  alt=""
                  width="450"
                  height="300"
                  layout="responsive"
                  i-amphtml-layout="responsive"
                  ><i-amphtml-sizer
                    style="padding-top: 66.6667%"
                  ></i-amphtml-sizer>
                  <div placeholder="" class="placeholder amp-hidden">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                  <img
                    decoding="async"
                    alt=""
                    src="assets/images/13.jpg"
                    class="i-amphtml-fill-content i-amphtml-replaced-content"
                /></amp-img>
              </div>
              <div
                class="carousel-img mbr-col-sm-12 amp-carousel-slide amp-scrollable-carousel-slide"
              >
                <amp-img
                  class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  src="assets/images/10.jpg"
                  alt=""
                  width="450"
                  height="300"
                  layout="responsive"
                  i-amphtml-layout="responsive"
                  ><i-amphtml-sizer
                    style="padding-top: 66.6667%"
                  ></i-amphtml-sizer>
                  <div placeholder="" class="placeholder amp-hidden">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                  <img
                    decoding="async"
                    alt=""
                    src="assets/images/10.jpg"
                    class="i-amphtml-fill-content i-amphtml-replaced-content"
                /></amp-img>
              </div>
              <div
                class="carousel-img mbr-col-sm-12 amp-carousel-slide amp-scrollable-carousel-slide"
              >
                <amp-img
                  class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  src="assets/images/08.jpg"
                  alt=""
                  width="450"
                  height="300"
                  layout="responsive"
                  i-amphtml-layout="responsive"
                  ><i-amphtml-sizer
                    style="padding-top: 66.6667%"
                  ></i-amphtml-sizer>
                  <div placeholder="" class="placeholder amp-hidden">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                  <img
                    decoding="async"
                    alt=""
                    src="assets/images/08.jpg"
                    class="i-amphtml-fill-content i-amphtml-replaced-content"
                /></amp-img>
              </div>
            </div>
            <div
              tabindex="0"
              class="amp-carousel-button amp-carousel-button-prev"
              role="presentation"
              title="Previous item in carousel"
              aria-disabled="false"
            ></div>
            <div
              tabindex="-1"
              class="amp-carousel-button amp-carousel-button-next amp-disabled"
              role="presentation"
              title="Next item in carousel"
              aria-disabled="true"
            ></div
          ></amp-carousel>
        </div>
      </div>
    </section>

    <section
      id="testimonials1-b"
      class="testimonials1 slider slider-with-bullets cid-srwRn6BEv2 d-none"
    >
      <div class="container">
        <div class="slider-box" data-autoplay="true" data-delay="4000">
          <amp-carousel
            id="carouseltestimonials1b"
            class="carousel i-amphtml-element i-amphtml-layout-fill i-amphtml-layout-size-defined i-amphtml-slidescroll i-amphtml-carousel-has-controls i-amphtml-built i-amphtml-layout"
            loop=""
            layout="fill"
            type="slides"
            on="slideChange:AMP.setState({selectedtestimonials1b: {slide :event.index}})"
            autoplay=""
            delay="4000"
            i-amphtml-layout="fill"
            ><div
              tabindex="-1"
              class="i-amphtml-slides-container i-amphtml-slidescroll-no-snap"
              aria-live="polite"
            >
              <div
                class="i-amphtml-slide-item i-amphtml-slide-item-show"
                style="order: 2"
              >
                <div
                  class="carousel-img mbr-col-sm-12 amp-carousel-slide"
                  aria-hidden="false"
                >
                  <div class="align-center">
                    <div class="card-img">
                      <div class="iconfont-wrapper">
                        <span
                          class="mbr-iconfont mobi-mbri-quote-right mobi-mbri"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="32"
                            height="32"
                          >
                            <path
                              d="M15.9 20.4l7.1-5.9V3H13v10h3.6l-4 4 3.3 3.4zM15 11V5h6v8.5l-4.9 4.1-.7-.6 3.6-3.6V11h-4zM.6 17L4 20.4l7.1-5.9V3H1v10h3.6l-4 4zM3 11V5h6v8.5l-4.9 4.1-.7-.6L7 13.4V11H3z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <p
                      class="mbr-fonts-style mbr-black rewiew mbr-pb-5 mbr-pt-2 display-5"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Eius ut similique, enim dignissimos delectus provident
                      placeat reprehenderit tempore.
                    </p>
                    <p class="mbr-fonts-style author mbr-pb-2 display-7">
                      Kate White
                    </p>
                    <p
                      class="mbr-fonts-style profession mbr-semibold display-4"
                    >
                      IT DEVELOPMENT
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="i-amphtml-slide-item i-amphtml-slide-item-show"
                style="order: 3"
              >
                <div
                  class="carousel-img mbr-col-sm-12 amp-carousel-slide"
                  aria-hidden="true"
                >
                  <div class="align-center">
                    <div class="card-img">
                      <div class="iconfont-wrapper">
                        <span
                          class="mbr-iconfont mobi-mbri-quote-right mobi-mbri"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="32"
                            height="32"
                          >
                            <path
                              d="M15.9 20.4l7.1-5.9V3H13v10h3.6l-4 4 3.3 3.4zM15 11V5h6v8.5l-4.9 4.1-.7-.6 3.6-3.6V11h-4zM.6 17L4 20.4l7.1-5.9V3H1v10h3.6l-4 4zM3 11V5h6v8.5l-4.9 4.1-.7-.6L7 13.4V11H3z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <p
                      class="mbr-fonts-style mbr-black rewiew mbr-pb-5 mbr-pt-2 display-5"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      In dolores animi dicta fugit iure, asperiores adipisci
                      similique illo ex blanditiis deleniti libero!
                    </p>
                    <p class="mbr-fonts-style author mbr-pb-2 display-7">
                      Jake Black
                    </p>
                    <p
                      class="mbr-fonts-style profession mbr-semibold display-4"
                    >
                      HEAD OF SALES
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="i-amphtml-slide-item i-amphtml-slide-item-show"
                style="order: 1"
              >
                <div
                  class="carousel-img mbr-col-sm-12 amp-carousel-slide"
                  aria-hidden="true"
                >
                  <div class="align-center">
                    <div class="card-img">
                      <div class="iconfont-wrapper">
                        <span
                          class="mbr-iconfont mobi-mbri-quote-right mobi-mbri"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="32"
                            height="32"
                          >
                            <path
                              d="M15.9 20.4l7.1-5.9V3H13v10h3.6l-4 4 3.3 3.4zM15 11V5h6v8.5l-4.9 4.1-.7-.6 3.6-3.6V11h-4zM.6 17L4 20.4l7.1-5.9V3H1v10h3.6l-4 4zM3 11V5h6v8.5l-4.9 4.1-.7-.6L7 13.4V11H3z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <p
                      class="mbr-fonts-style mbr-black rewiew mbr-pb-5 mbr-pt-2 display-5"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Perspiciatis iste neque voluptatem facere doloribus
                      maiores deleniti libero!
                    </p>
                    <p class="mbr-fonts-style author mbr-pb-2 display-7">
                      Jake Black
                    </p>
                    <p
                      class="mbr-fonts-style profession mbr-semibold display-4"
                    >
                      HEAD OF SALES
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              tabindex="0"
              class="amp-carousel-button amp-carousel-button-prev"
              role="button"
              title="Previous item in carousel (3 of 3)"
              aria-disabled="false"
            ></div>
            <div
              tabindex="0"
              class="amp-carousel-button amp-carousel-button-next"
              role="button"
              title="Next item in carousel (2 of 3)"
              aria-disabled="false"
            ></div
          ></amp-carousel>

          <div class="dots-wrapper mbr-flex mbr-jc-c">
            <div class="dots">
              <span
                role="button"
                tabindex="0"
                on="tap:carouseltestimonials1b.goToSlide(index=0)"
                class="current"
              ></span>
            </div>
            <div class="dots">
              <span
                role="button"
                tabindex="1"
                on="tap:carouseltestimonials1b.goToSlide(index=1)"
              ></span>
            </div>
            <div class="dots">
              <span
                role="button"
                tabindex="2"
                on="tap:carouseltestimonials1b.goToSlide(index=2)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features16-a" class="features16 cid-srwRb2UZyl d-none">
      <div class="container">
        <div class="wrapper">
          <div class="mbr-row">
            <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6">
              <div class="image-block">
                <amp-img
                  src="assets/images/11.jpeg"
                  alt=""
                  width="538"
                  height="538"
                  layout="responsive"
                  class="mobirise-loader mbr-m-auto i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  i-amphtml-layout="responsive"
                  ><i-amphtml-sizer style="padding-top: 100%"></i-amphtml-sizer>
                  <div placeholder="" class="placeholder amp-hidden">
                    <div class="mobirise-spinner">
                      <em></em>
                      <em></em>
                      <em></em>
                    </div>
                  </div>

                  <img
                    decoding="async"
                    alt=""
                    src="assets/images/11.jpeg"
                    class="i-amphtml-fill-content i-amphtml-replaced-content"
                /></amp-img>
              </div>
            </div>
            <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-6">
              <div class="section_title">
                <h3
                  class="mbr-fonts-style mbr-section-title mbr-black mbr-semibold mbr-pb-3 display-5"
                >
                  We create a workspace
                </h3>
                <div class="card_location mbr-flex mbr-pt-1 mbr-pb-1">
                  <span class="mbr-iconfont mobi-mbri-calendar mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M19 3V2h-2v1H7V2H5v1H0v19h24V3h-5zm3 17H2V5h3v1h2V5h10v1h2V5h3v15zM5 12h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2zM5 8h2v2H5V8zm4 0h2v2H9V8zm4 0h2v2h-2V8zm4 0h2v2h-2V8zM5 16h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="card_location mbr-text info mbr-fonts-style display-4"
                  >
                    March 13, 2025 - March 27, 2025
                  </p>
                </div>
                <div class="card_location mbr-flex mbr-pt-1 mbr-pb-1">
                  <span class="mbr-iconfont mobi-mbri-clock mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10zM11 4h2v9H7v-2h4V4z"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="card_location mbr-text info mbr-fonts-style display-4"
                  >
                    18:00 pm - 21:00 pm
                  </p>
                </div>
                <div class="card_location mbr-flex mbr-pt-1 mbr-pb-1">
                  <span class="mbr-iconfont mobi-mbri-home mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M12 1.8L.4 10.2l1.2 1.6L4 10.1V22h16V10.1l2.4 1.8 1.2-1.6L12 1.8zM13 20h-2v-5h2v5zm5 0h-3v-7H9v7H6V8.6l6-4.4 6 4.4V20z"
                      ></path>
                    </svg>
                  </span>
                  <p class="mbr-text info mbr-fonts-style display-4">
                    Mobirise Studio
                  </p>
                </div>
                <div class="card_location mbr-flex mbr-pt-1 mbr-pb-1">
                  <span class="mbr-iconfont mobi-mbri-map-pin mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M12 0C7 0 3 4 3 9c0 7.4 8.1 14.3 8.4 14.5l.6.5.6-.5c.3-.2 8.4-7.1 8.4-14.5 0-5-4-9-9-9zm0 21.5C10.2 19.9 5 14.3 5 9c0-3.9 3.1-7 7-7s7 3.1 7 7c0 5.3-5.2 10.9-7 12.5zM12 5C9.8 5 8 6.8 8 9s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                      ></path>
                    </svg>
                  </span>
                  <p class="mbr-text info mbr-fonts-style display-4">
                    350 5th Ave, New York, NY 10118
                  </p>
                </div>
                <p class="mbr-fonts-style mbr-text text mbr-py-1 display-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Deleniti excepturi molestiae laudantium a perferendis quis,
                  laborum vero!
                </p>
                <div class="mbr-section-btn mbr-pt-2">
                  <a class="btn btn-sm btn-primary display-4" href="#"
                    ><span
                      class="mobi-mbri mobi-mbri-arrow-next mbr-iconfont mbr-iconfont-btn"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="11"
                        height="11"
                      >
                        <path
                          d="M4.6 22.6L15.2 12 4.6 1.4 6 0l12 12L6 24z"
                        ></path></svg></span
                    >Read More</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="social1-e" class="social1 cid-srwRqnJ5kL d-none">
      <div class="container">
        <div class="mbr-row mbr-jc-c">
          <div class="mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12">
            <div class="section_title">
              <h4
                class="mbr-section-subtitle align-center mbr-fonts-style mbr-semibold display-4"
              >
                COWORKING
              </h4>
              <h3
                class="mbr-fonts-style align-center mbr-section-title mbr-semibold display-2"
              >
                Follow us
              </h3>
            </div>
          </div>
          <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 m_md">
            <div class="card_wrap">
              <amp-img
                src="assets/images/07.jpg"
                layout="responsive"
                width="381.90327613104523"
                height="255"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.7708%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>
                <a href="index.html">
                  <span class="amp-iconfont fa-facebook-f fa"
                    ><svg
                      width="32"
                      height="32"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"
                      ></path>
                    </svg>
                  </span>
                </a>
                <img
                  decoding="async"
                  alt=""
                  src="assets/images/07.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 m_md">
            <div class="card_wrap">
              <amp-img
                src="assets/images/10.jpg"
                layout="responsive"
                width="382.5"
                height="255"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.6667%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>
                <a href="index.html">
                  <span class="amp-iconfont fa-instagram fa"
                    ><svg
                      width="32"
                      height="32"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z"
                      ></path>
                    </svg>
                  </span>
                </a>
                <img
                  decoding="async"
                  alt=""
                  src="assets/images/10.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 m_md">
            <div class="card_wrap">
              <amp-img
                src="assets/images/08.jpg"
                layout="responsive"
                width="382.5"
                height="255"
                alt=""
                class="mobirise-loader i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                i-amphtml-layout="responsive"
                ><i-amphtml-sizer
                  style="padding-top: 66.6667%"
                ></i-amphtml-sizer>
                <div placeholder="" class="placeholder amp-hidden">
                  <div class="mobirise-spinner">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                </div>
                <a href="index.html">
                  <span class="amp-iconfont fa-twitter fa"
                    ><svg
                      width="32"
                      height="32"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"
                      ></path>
                    </svg>
                  </span>
                </a>
                <img
                  decoding="async"
                  alt=""
                  src="assets/images/08.jpg"
                  class="i-amphtml-fill-content i-amphtml-replaced-content"
              /></amp-img>
            </div>
          </div>

          <div class="mbr-col-sm-12 mbr-col-md-6 mbr-col-lg-3 m_md">
            <div class="card_wrap info_card">
              <h5 class="mbr-fonts-style mbr-section-title mbr-white display-5">
                Lorem ipsum dolor sit amet.
              </h5>
              <div class="social_block mbr-flex mbr-jc-c">
                <a href="#span#" target="_blank">
                  <span class="amp-iconfont fa-pinterest-p fa"
                    ><svg
                      width="32"
                      height="32"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M256 597q0-108 37.5-203.5t103.5-166.5 152-123 185-78 202-26q158 0 294 66.5t221 193.5 85 287q0 96-19 188t-60 177-100 149.5-145 103-189 38.5q-68 0-135-32t-96-88q-10 39-28 112.5t-23.5 95-20.5 71-26 71-32 62.5-46 77.5-62 86.5l-14 5-9-10q-15-157-15-188 0-92 21.5-206.5t66.5-287.5 52-203q-32-65-32-169 0-83 52-156t132-73q61 0 95 40.5t34 102.5q0 66-44 191t-44 187q0 63 45 104.5t109 41.5q55 0 102-25t78.5-68 56-95 38-110.5 20-111 6.5-99.5q0-173-109.5-269.5t-285.5-96.5q-200 0-334 129.5t-134 328.5q0 44 12.5 85t27 65 27 45.5 12.5 30.5q0 28-15 73t-37 45q-2 0-17-3-51-15-90.5-56t-61-94.5-32.5-108-11-106.5z"
                      ></path>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import BookList from "@/components/BookList.vue";
import VersionList from "@/components/VersionList.vue";

defineComponent({
  name: "HomeView",
  components: { VersionList, BookList },
});

const store = useStore();

const versions = computed(() => store.state.versions);
const books = computed(() => store.getters.getBooksByTestament());
</script>
