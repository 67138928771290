export function CopyToClipboard(text: string) {
  try {
    const textArea = document.createElement("textarea");
    textArea.setAttribute("style", "width:1px;border:0;opacity:0;");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  } catch (e: any) {
    console.error(e.toString());
  }
}
