import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createMetaManager } from "vue-meta";
import VueGtag from "vue-gtag";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faVuejs } from "@fortawesome/free-brands-svg-icons";

import "bootstrap";
import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/app.scss";

const app = createApp(App).use(store).use(router).use(createMetaManager());

if (process.env.NODE_ENV === "production") {
  app.use(VueGtag, {
    config: {
      id: "G-XY50EKZSZV",
    },
  });
}

library.add(fas, faVuejs);
app.component("FontAwesomeIcon", FontAwesomeIcon);

await store.dispatch("fetchData");

await router.isReady();

app.mount("#app");
