<template>
  <div :id="`verse-item-${verse.verse}`" class="verse-item" :class="rootClass">
    <p class="m-0">
      <sup>{{ verse.verse }}.</sup>&nbsp;
      {{ verse.verse_text }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { BookApi, VerseApi } from "@/types/app";
import { PropType, defineComponent } from "vue";

defineProps({
  verse: {
    type: Object as PropType<VerseApi>,
    required: true,
  },
  book: {
    type: Object as PropType<BookApi>,
    required: true,
  },
  rootClass: {
    type: String,
    required: false,
    default: () => "",
  },
});

defineComponent({
  name: "VerseItem",
});
</script>
