<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Modal } from "bootstrap";
defineProps({
  title: {
    type: String,
    default: "Modal Title",
  },
  modalId: {
    type: String,
    default: "modalBootstrap",
  },
});
let modalRef = ref();
let thisModalObj: any = null;

onMounted(() => {
  thisModalObj = new Modal(modalRef.value);
});

function show() {
  thisModalObj.show();
}

function hide() {
  thisModalObj.hide();
}

function onEvent(event: string, callback: typeof Function) {
  modalRef.value.addEventListener(event, callback);
}

function onHide(callback: typeof Function) {
  onEvent("hide.bs.modal", callback);
}

function onHidden(callback: typeof Function) {
  onEvent("hidden.bs.modal", callback);
}

function onShown(callback: typeof Function) {
  onEvent("shown.bs.modal", callback);
}

function onShow(callback: typeof Function) {
  onEvent("show.bs.modal", callback);
}

defineExpose({ show, hide, onHide, onHidden, onShown, onShow });
</script>

<template>
  <div
    :id="modalId"
    ref="modalRef"
    class="modal fade"
    tabindex="-1"
    aria-labelledby=""
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
