import { RequestHelper } from "@/helper/request-helper";
import { VerseApi } from "@/types/app";

export declare type BookVersionsChapterVerseResponse = {
  status: string;
  verses?: Record<string, VerseApi[]>;
  message?: string;
};

class ClassApiRepository {
  public async getBookVersionsChapterVerse(
    version: string[],
    book: string,
    chapter: string,
    verses: number[]
  ): Promise<BookVersionsChapterVerseResponse> {
    try {
      return await RequestHelper.postJson("/book/versions/chapter/verse", {
        version,
        book,
        chapter,
        verses,
      });
    } catch (e: any) {
      return {
        status: "error",
        message: "Não foi possível buscar as informações",
      };
    }
  }
}

export default new ClassApiRepository();
