<script lang="ts" setup>
import { PropType } from "vue";
import { VersionApi } from "@/types/app";

defineProps({
  versions: {
    type: [] as PropType<VersionApi[]>,
    required: false,
    default: () => [] as PropType<VersionApi[]>,
  },
});
</script>

<template>
  <div>
    <ul v-if="versions.length > 0" class="list-unstyled">
      <li v-for="version in versions" :key="version.code">
        <a href="javascript:void(0)" @click="chooseVersion(version.code)">{{
          version.name
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import UrlHelper from "@/helper/url-helper";
import { GetLinkHelper } from "@/helper/link-helper";

export default defineComponent({
  name: "VersionList",
  data() {
    return {
      versionSelected: "",
      linkHelper: GetLinkHelper(),
    };
  },
  computed: {
    ...mapGetters(["getVersionByCode"]),
  },
  methods: {
    chooseVersion(code: string): void {
      if (this.getVersionByCode(code) === undefined) {
        UrlHelper.RouterBack(this.$router);
        return;
      }

      this.setVersion(code);
      this.$router.push(this.linkHelper.getVersionBooksPage(code));
    },
    ...mapMutations(["setVersion"]),
  },
});
</script>
