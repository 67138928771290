<template>
  <div class="verse-modal-compare">
    <Modal
      ref="modalVerse"
      :title="`Comparar versículos`"
      :modal-id="`modal-${book.abbrev}`"
    >
      <template #body>
        <div v-if="!showVersesToCompare">
          <div
            v-for="version in versions"
            :key="version.code"
            class="form-check"
          >
            <input
              :id="`version-${version.code}`"
              v-model="versionsToCompare"
              class="form-check-input"
              type="checkbox"
              :value="version.code"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ version.name }} ({{ version.code.toUpperCase() }})
            </label>
          </div>
        </div>
        <div v-else-if="isLoading" class="text-center">
          <FontAwesomeIcon
            :icon="['fa-solid', 'fa-spinner']"
            :spin="true"
            size="2x"
          />
        </div>
        <div v-else>
          <p>
            Versão Atual:
            <span class="fw-bold" v-text="versionSelected.toUpperCase()"></span>
          </p>

          <p v-for="verse in verses" :key="verse.verse">
            <sup>{{ verse.verse }}.</sup>
            &nbsp;{{ verse.verse_text }}
          </p>

          <div
            v-for="(versesCompare, versionCompare) in responseResult.verses"
            :key="`vc-${versionCompare}`"
          >
            <hr />
            <p>
              Versão:
              <span
                class="fw-bold"
                v-text="versionCompare.toUpperCase()"
              ></span>
            </p>

            <p v-for="verse in versesCompare" :key="verse.verse">
              <sup>{{ verse.verse }}.</sup>
              &nbsp;{{ verse.verse_text }}
            </p>
          </div>
        </div>
      </template>
      <template #footer>
        <button
          v-if="!showVersesToCompare"
          class="btn btn-primary"
          @click="() => fetchVerseCompare(book.abbrev, chapter, verses)"
        >
          Comparar
        </button>
        <button v-else class="btn btn-primary" data-bs-dismiss="modal">
          Fechar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, computed, PropType, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { BookApi, VerseApi, VersionApi } from "@/types/app";
import ApiRepository, {
  BookVersionsChapterVerseResponse,
} from "@/repositories/ApiRepository";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Modal from "@/components/ModalBootstrap.vue";

defineComponent({
  name: "VerseModalCompare",
  components: { Modal, FontAwesomeIcon },
});

const props = defineProps({
  book: {
    type: Object as PropType<BookApi>,
    required: true,
  },
  chapter: {
    type: Number,
    required: true,
  },
  verses: {
    type: Array as PropType<VerseApi[]>,
    required: true,
  },
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const modalVerse = ref();
const versionsToCompare = ref([] as string[]);
const responseResult = ref({
  status: "error",
} as BookVersionsChapterVerseResponse);
const isLoading = ref(false);

const store = useStore();

// Computed
const versionSelected = computed(() => store.state.version);
const storeVersions = computed(() => store.state.versions);
const versions = computed(() =>
  storeVersions.value.filter(
    (v: VersionApi) => v.code !== versionSelected.value
  )
);
const showVersesToCompare = computed(
  () => responseResult.value.status === "success"
);

// Methods
function openModalCompare() {
  modalVerse.value.show();
}

async function fetchVerseCompare(
  book: string,
  chapter: number,
  verses: VerseApi[]
) {
  if (versionsToCompare.value.length < 1) {
    return;
  }

  isLoading.value = true;

  responseResult.value = await ApiRepository.getBookVersionsChapterVerse(
    versionsToCompare.value,
    book,
    chapter.toString(),
    verses.map((v: VerseApi) => {
      return v.verse;
    })
  );

  isLoading.value = false;
}

function cleanVariables() {
  responseResult.value = { status: "error" };
  versionsToCompare.value = [];
}

onMounted(() => {
  modalVerse.value.onHidden(cleanVariables);
  modalVerse.value.onHide(props.onClose);

  if (props.show === true) {
    openModalCompare();
  } else {
    modalVerse.value.hide();
  }
});
</script>
