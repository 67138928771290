import { GetRouterHelper, RouterHelper } from "@/helper/router-helper";

export class LinkHelper {
  constructor(private readonly routerHelper: RouterHelper) {}

  public getVersionBooksPage(version: string) {
    return this.routerHelper.getLink(
      RouterHelper.VersionBooks,
      new Map<string, string>([["version", version]])
    );
  }

  public getBookChaptersPage(version: string, book: string) {
    return this.routerHelper.getLink(
      RouterHelper.BookChapters,
      new Map<string, string>([
        ["version", version],
        ["book", book],
      ])
    );
  }

  public getBookChapterVersesPage(
    version: string,
    book: string,
    chapter: string
  ) {
    return this.routerHelper.getLink(
      RouterHelper.BookChaptersVerses,
      new Map<string, string>([
        ["version", version],
        ["book", book],
        ["chapter", chapter],
      ])
    );
  }
}

export function GetLinkHelper() {
  return new LinkHelper(GetRouterHelper());
}
