<template>
  <section id="menu1-0" class="menu horizontal-menu cid-srugmNvvB2">
    <nav class="navbar navbar-dropdown navbar-expand-lg navbar-fixed-top">
      <div class="menu-container container">
        <div class="navbar-brand">
          <p
            class="brand-name mbr-fonts-style mbr-semibold display-7 text-center"
          >
            <router-link to="/" class="mbr-semibold text-white">
              Medite nas Escrituras
            </router-link>
          </p>
        </div>
        <div
          id="navbarSupportedContent"
          class="collapse navbar-collapse justify-content-end"
        >
          <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
            <!-- <li class="nav-item">
              <router-link to="/" class="nav-link link text-white display-4">
                Home
              </router-link>
            </li> -->
            <li class="nav-item">
              <router-link
                to="/about"
                class="nav-link link text-white display-4"
                >Sobre nós
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/contact"
                class="nav-link link text-white display-4"
                >Contato
              </router-link>
            </li>
            <!--
            <li class="nav-item dropdown">
              <a
                class="nav-link link dropdown-toggle text-white display-4"
                data-toggle="dropdown-submenu"
                aria-expanded="false"
                >View</a
              >
              <div class="dropdown-menu">
                <a class="dropdown-item text-white display-4" href="#"
                  >Reviews</a
                ><a
                  class="dropdown-item text-white display-4"
                  href="#"
                  aria-expanded="false"
                  >Contacts</a
                ><a
                  class="dropdown-item text-white display-4"
                  href="#"
                  aria-expanded="false"
                  >Examples</a
                >
              </div>
            </li>
            -->
          </ul>

          <!-- <div class="menu-social-list">
            <a href="#" target="_blank">
              <span class="amp-iconfont fa-facebook-f fa"
                ><svg
                  width="22"
                  height="22"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                ></svg>
              </span>
            </a>
            <a href="#" target="_blank">
              <span class="amp-iconfont fa-instagram fa">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                ></svg>
              </span>
            </a>
            <a href="#" target="_blank">
              <span class="amp-iconfont fa-twitter fa"
                ><svg
                  width="22"
                  height="22"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                ></svg>
              </span>
            </a>
          </div> -->
        </div>

        <button
          on="tap:sidebar.toggle"
          class="ampstart-btn hamburger sticky-but"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </nav>
  </section>

  <slot />

  <section id="footer" class="footer1 cid-srwRr85KE4">
    <div class="container">
      <div class="mbr-row">
        <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-5">
          <div class="wrap_left">
            <div class="logo_wrap mbr-pb-3">
              <div class="brand">
                <h3
                  class="mbr-fonts-style brand_name mbr-white mbr-semibold display-5"
                >
                  Medite nas Escrituras
                </h3>
              </div>
            </div>
            <p class="mbr-fonts-style mbr-text info mbr-pb-2 display-4">
              Lâmpada para os meus pés é tua palavra, e luz para o meu caminho.
              Salmos 119:105
            </p>
            <!--
            <div class="mbr-pb-2 align-center">
              <div class="social_list mbr-flex flex-wrap">
                <a href="#" target="_blank">
                  <span class="amp-iconfont fa-facebook-f fa"
                    ><svg
                      width="14"
                      height="14"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"
                      ></path>
                    </svg>
                  </span> </a
                ><a href="#" target="_blank">
                  <span class="amp-iconfont fa-instagram fa"
                    ><svg
                      width="14"
                      height="14"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z"
                      ></path>
                    </svg>
                  </span> </a
                ><a href="#" target="_blank">
                  <span class="amp-iconfont fa-twitter fa"
                    ><svg
                      width="14"
                      height="14"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <path
                        d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"
                      ></path>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
            -->

            <div class="mbr-form" data-form-type="formoid">
              <!--
              <form
                id="myFormfooter1f"
                method="POST"
                class="mbr-form align-center i-amphtml-form"
                action-xhr="https://formoid.net/api/amp-form/push"
                data-form-title="Mobirise Form"
                on="submit:myFormfooter1f.clear;submit-success:AMP.setState({myStatefooter1f:{formdata01footer1f:{stateformdata01footer1f: '', valueformdata01footer1f: ''}}});submit-error:AMP.setState({myStatefooter1f:{formdata01footer1f:{stateformdata01footer1f: myStatefooter1f.formdata01footer1f.valueformdata01footer1f}}})"
              >
                <input
                  type="hidden"
                  name="email"
                  data-form-email="true"
                  value="eaBh4PmXm+wwevtE9uaO42IufxIz0FGy+yNFso4xSTa8LzRzcGyt9MtD+NUOIKsk705zMk9zAWzxaCDNErGuQBdoxbJYfUimS6sULMCNdXXxO7YG/HqRefokqTiFLHO4"
                />
                <div class="mbr-row">
                  <div
                    submit-success=""
                    class="mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12"
                  >
                    <p data-form-alert="" type="amp-mustache">
                      Subscription successful!
                    </p>
                  </div>
                  <div
                    submit-error=""
                    class="mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12"
                  >
                    <p data-form-alert="" type="amp-mustache">Failed!</p>
                  </div>
                </div>
                <div class="dragArea mbr-row">
                  <div data-for="form[data][0][1]" class="mbr-col field">
                    <input
                      id="form[data][0][0]-footer1-f"
                      type="hidden"
                      name="form[data][0][0]"
                      value="Email"
                      data-form-field=""
                    />
                    <input
                      id="form[data][0][1]-footer1-f"
                      on="change:AMP.setState({myStatefooter1f:{formdata01footer1f:{valueformdata01footer1f: event.value}}})"
                      type="email"
                      name="form[data][0][1]"
                      placeholder="Subscribe"
                      data-form-field="Email"
                      class="field-input display-4"
                      value=""
                    />
                  </div>
                  <div class="mbr-col-auto mbr-section-btn field">
                    <button
                      type="submit"
                      class="btn btn-sm btn-form btn-black display-4"
                    >
                      Go<span
                        class="mobi-mbri mobi-mbri-arrow-next mbr-iconfont mbr-iconfont-btn"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          width="11"
                          height="11"
                        >
                          <path
                            d="M4.6 22.6L15.2 12 4.6 1.4 6 0l12 12L6 24z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
              -->
            </div>
          </div>
        </div>

        <div class="mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-7">
          <div class="mbr-row">
            <div class="mbr-col-lg-6 mbr-col-md-6 mbr-col-sm-12">
              <div class="about_wrap">
                <h4
                  class="mbr-fonts-style mbr-white mbr-section-title group-title mbr-pb-4 mbr-semibold display-7"
                >
                  Sobre
                </h4>
                <p class="about mbr-text mbr-pb-1 mbr-fonts-style display-4">
                  Medite nas Escrituras é uma plataforma que disponibiliza
                  versões da bíblia para meditação e estudo.
                </p>
                <!--
                <div class="mbr-flex contacts mbr-pt-5">
                  <span class="mbr-iconfont mobi-mbri-map-pin mobi-mbri"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M12 0C7 0 3 4 3 9c0 7.4 8.1 14.3 8.4 14.5l.6.5.6-.5c.3-.2 8.4-7.1 8.4-14.5 0-5-4-9-9-9zm0 21.5C10.2 19.9 5 14.3 5 9c0-3.9 3.1-7 7-7s7 3.1 7 7c0 5.3-5.2 10.9-7 12.5zM12 5C9.8 5 8 6.8 8 9s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="mbr-fonts-style adress mbr-text mbr-semibold mbr-white display-4"
                  >
                    <a href="#" class="text-white"
                      >350 5th Ave, New York, NY 10118</a
                    >
                  </p>
                </div>
                -->
              </div>
            </div>
            <div class="mbr-col-lg-6 mbr-col-md-6 mbr-col-sm-12">
              <!-- <h4
                class="mbr-fonts-style mbr-white mbr-section-title group-title mbr-pb-4 mbr-semibold display-7"
              >
                Links rápido
              </h4>
              <div class="items mbr-pb-5">
                <p class="item mbr-pb-1 mbr-fonts-style display-4">
                  <a href="#" class="text-white">- Orders</a>
                </p>
                <p class="item mbr-pb-1 mbr-fonts-style display-4">
                  <a href="#" class="text-white">- Support</a>
                </p>
                <p class="item mbr-pb-1 mbr-fonts-style display-4">
                  <a href="#" class="text-white">- Forums</a>
                </p>
                <p class="item mbr-pb-1 mbr-fonts-style display-4">
                  <a href="#" class="text-white">- Privacy &amp; Policy</a>
                </p>
                <p class="item mbr-pb-1 mbr-fonts-style display-4">
                  <a href="#" class="text-white">- Terms</a>
                </p>
              </div> -->

              <div class="contacts">
                <div class="d-block w-100">
                  <h4
                    class="mbr-fonts-style mbr-white mbr-section-title group-title mbr-pb-4 mbr-semibold display-7"
                  >
                    Dados de Contato
                  </h4>
                </div>
              </div>

              <div class="mbr-flex contacts">
                <span class="mbr-iconfont mobi-mbri-letter mobi-mbri"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M0 4v16h24V4H0zm19.8 2L12 10.7 4.2 6h15.6zM2 18V7l10 6 10-6v11H2z"
                    ></path>
                  </svg>
                </span>
                <p
                  class="mbr-fonts-style mail mbr-text mbr-semibold mbr-white display-4"
                >
                  <a
                    href="mailto:contato@meditenasescrituras.com.br"
                    class="text-white"
                    >contato@meditenasescrituras.com.br</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <p class="copyright mbr-fonts-style mbr-pt-1 display-4">
          <a href="javascript:void(0)" class="text-white">
            © Todos os direitos reservados
            {{ new Date().getFullYear() }} - Medite nas Escrituras
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { defineComponent } from "vue";

defineComponent({
  name: "DefaultLayout",
});
</script>
