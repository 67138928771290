<script lang="ts" setup>
import { useMeta } from "vue-meta";
import ScreenLoading from "./components/ScreenLoading.vue";

useMeta({
  title: process.env.VUE_APP_APP_NAME,
  description:
    "Medite nas Escrituras de Deus é um site que disponibiliza versões da Bíblia para leitura e meditação",
  keywords: "biblia, biblia online, Palavra de Deus",
  htmlAttrs: { lang: "en", amp: true },
});
</script>

<template>
  <metainfo>
    <template #title="{ content }">{{ content }}</template>
    <template #description="{ content }">{{ content }}</template>
    <template #keywords="{ content }">{{ content }}</template>
  </metainfo>

  <screen-loading />

  <component :is="$route.meta.layout || `div`">
    <router-view :key="$route.fullPath" />
  </component>
</template>
