import { Router, RouteRecordNormalized, useRouter } from "vue-router";

export class RouterHelper {
  public static readonly HomeName = "home";
  public static readonly VersionBooks = "version-books";
  public static readonly BookChapters = "book-chapters";
  public static readonly BookChaptersVerses = "book-chapter-verses";

  constructor(private readonly router: Router) {}

  public getRouteByName(name: string): RouteRecordNormalized | undefined {
    return this.router.getRoutes().find((r) => r.name === name);
  }

  public getLink(name: string, params: Map<string, string>) {
    const route = this.getRouteByName(name);

    if (route === undefined) {
      return "";
    }

    let path = route.path as string;

    for (const [key, value] of params) {
      path = path.replace(`:${key}`, value);
    }

    return path;
  }
}

export function GetRouterHelper() {
  return new RouterHelper(useRouter());
}
