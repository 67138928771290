<template>
  <div v-if="isLoading" class="screen-loading">
    <div class="screen-loading__overlay"></div>
    <FontAwesomeIcon :icon="['fas', 'spinner']" size="3x" :spin="true" />
  </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

defineComponent({
  name: "ScreenLoading",
  components: { FontAwesomeIcon },
});

const store = useStore();

const isLoading = computed(() => store.state.isScreenLoading);
</script>
