class ClassRequestHelper {
  constructor(private apiUrl: string | undefined) {}

  public async getJson(uri: string, body: Record<any, any> | null = null) {
    const queryString: string =
      body !== null ? "?" + this.queryStringFromObject(body) : "";

    const response = await fetch(this.apiUrl + uri + queryString, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  }

  public async postJson(uri: string, body: Record<any, any> | null = null) {
    const data: object = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    };

    const response = await fetch(this.apiUrl + uri, data);

    return await response.json();
  }

  private queryStringFromObject(obj: Record<any, any> | null = null): string {
    if (obj === undefined || obj === null) {
      return "";
    }

    return Object.keys(obj)
      .map((key: string) => {
        return `${key}=${encodeURIComponent(obj[key])}`;
      })
      .join("&");
  }
}

export default ClassRequestHelper;

export const RequestHelper = new ClassRequestHelper(
  process.env.VUE_APP_API_URL
);
