<template>
  <section class="pg-book-chapters cid-srwQZCq9Ba">
    <div class="container">
      <BreadCrumb :breadcrumb="breadcrumb" />

      <h1 class="pg-book-chapters__book-title">
        {{ bookName }} - {{ currentVersion.code.toUpperCase() }}
      </h1>

      <ul v-if="hasChapters" class="pg-book-chapters__list list-unstyled row">
        <li v-for="chapter in chapters" :key="chapter" class="col-lg-1 col-2">
          <router-link :to="getLinkChapter(chapter)">{{ chapter }}</router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { BookApi, VersionApi } from "@/types/app";
import { BreadCrumbType } from "@/types/app";
import { GetLinkHelper } from "@/helper/link-helper";
import { useRoute } from "vue-router";
import BreadCrumb from "@/components/BreadCrumb.vue";

defineComponent({
  name: "BookChapters",
  components: { BreadCrumb },
});

const store = useStore();
const route = useRoute();

const currentVersion = ref({ code: "", name: "" } as VersionApi);
const bookAbbrev = ref("");
const bookName = ref("");
const chapters = ref(0);
const breadcrumb = ref([] as BreadCrumbType[]);
const linkHelper = GetLinkHelper();

const hasChapters = computed(() => chapters.value > 0);

function getLinkChapter(chapter: number) {
  return linkHelper.getBookChapterVersesPage(
    store.state.version,
    bookAbbrev.value,
    chapter.toString()
  );
}

function fetchData() {
  // Get current version
  currentVersion.value = store.getters.getCurrentVersion();

  const data: BookApi | undefined = store.getters.getBookByAbbrev(
    route.params["book"]
  );

  if (data !== undefined) {
    bookAbbrev.value = data.abbrev;
    bookName.value = data.name;
    chapters.value = data.chapters;

    breadcrumb.value = [
      {
        name: "Home",
        link: "/",
        disabled: false,
      },
      {
        name: "Livros",
        link: linkHelper.getVersionBooksPage(store.state.version),
        disabled: false,
      },
      {
        name: "Capítulos",
        link: "",
        disabled: true,
      },
    ] as BreadCrumbType[];
  }
}

onMounted(() => {
  fetchData();
});
</script>
